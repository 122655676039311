.pb-cropper {
  position: relative;
  overflow: hidden;

  .pb-cropper-container-image {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .pb-cropper__backdrop {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.3;
    border-radius: inherit;
  }

  .pb-cropper__view-box {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
  }

  .pb-cropper__crop-box {
    height: 100%;
    width: 40%;
    position: absolute;
    cursor: move;

    .pb-cropper__view-box-image {
      max-height: none !important;
      max-width: none !important;
      min-height: 0 !important;
      min-width: 0 !important;
      backface-visibility: hidden;
      display: block;
    }
  }

  .pb-cropper__face,
  .pb-cropper__line,
  .pb-cropper__point {
    display: block;
    height: 100%;
    opacity: 0.1;
    position: absolute;
    width: 100%;
  }

  .pb-cropper__face {
    pointer-events: none;
    cursor: move;
    left: 0;
    top: 0;
  }

  .pb-cropper__line {
    background-color: var(--sync-text-color);
    opacity: 1;

    &.line-e {
      cursor: ew-resize;
      right: -0.2rem;
      top: 0;
      width: 0.2rem;
    }

    &.line-n {
      cursor: ns-resize;
      height: 0.2rem;
      left: 0;
      top: -0.2rem;
    }

    &.line-w {
      cursor: ew-resize;
      left: -0.2rem;
      top: 0;
      width: 0.2rem;
    }

    &.line-s {
      bottom: -0.2rem;
      cursor: ns-resize;
      height: 0.2rem;
      left: 0;
    }
  }

  .pb-cropper__dashed {
    border: 0 solid var(--sync-text-color);
    display: block;
    opacity: 0.6;
    position: absolute;

    &.dashed-h {
      border-bottom-width: 1px;
      border-top-width: 1px;
      height: calc(100% / 3);
      left: 0;
      top: calc(100% / 3);
      width: 100%;
    }

    &.dashed-v {
      border-left-width: 1px;
      border-right-width: 1px;
      height: 100%;
      left: calc(100% / 3);
      top: 0;
      width: calc(100% / 3);
    }
  }

  .pb-cropper__point {
    background-color: var(--sync-text-color);
    height: 5px;
    opacity: 1;
    width: 0.3rem;

    &.pb-cropper__point-corner {
      &::before {
        content: '';
        position: absolute;
        height: 0.3rem;
        width: 2.5rem;
        background-color: var(--sync-text-color);
      }
    }

    &.point-e {
      cursor: ew-resize;
      right: 0rem;
      top: 50%;
      height: 5rem;
      margin-top: -2.5rem;
    }

    &.point-n {
      cursor: ns-resize;
      left: 50%;
      margin-left: -0.5rem;
      top: -0.5rem;
    }

    &.point-w {
      cursor: ew-resize;
      left: 0rem;
      top: 50%;
      height: 5rem;
      margin-top: -2.5rem;
    }

    &.point-s {
      bottom: -0.5rem;
      cursor: s-resize;
      left: 50%;
      margin-left: -0.5rem;
    }

    &.point-ne {
      cursor: nesw-resize;
      right: 0rem;
      top: 0rem;
      height: 2.5rem;

      &::before {
        top: 0rem;
        right: 0;
      }
    }

    &.point-nw {
      cursor: nwse-resize;
      left: 0rem;
      top: 0rem;
      height: 2.5rem;

      &::before {
        top: 0rem;
        left: 0;
      }
    }

    &.point-sw {
      cursor: nesw-resize;
      bottom: 0;
      left: 0;
      height: 2.5rem;

      &::before {
        bottom: 0rem;
        left: 0;
      }
    }

    &.point-se {
      height: 2.5rem;
      right: 0;
      bottom: 0;

      &::before {
        bottom: 0rem;
        right: 0;
      }
    }
  }
}
