.printing-select-photo__wrapper {
}

.printing-select-photo__page-title {
  margin-bottom: 4rem;
}

.printing-select-photo__payment-info-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10rem;

  & > * {
    color: var(--sync-text-color);
    font-weight: 600;
  }
}

.printing-select-photo__photo-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  margin-top: 4rem;

  .printing-select-photo__photo-item {
    height: 20rem;
    width: auto;
    border: 0.3rem solid transparent;
    border-radius: 2rem;
    position: relative;

    &.active {
      border: 0.3rem solid var(--sync-button-border-color);
    }

    .printing-select-photo__actions {
      position: absolute;
      left: 1rem;
      top: 1rem;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;

      & > * {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        border: 1px solid var(--sync-button-border-color);
      }

      .printing-select-photo__action-picture {
        background-color: var(--sync-secondary-color);
      }

      .printing-select-photo__action-trash {
        background-color: var(--sync-primary-color);
      }

      svg {
        width: 2rem;
        height: 2rem;

        path {
          stroke: white;
        }
      }

      .pb-typography {
        background-color: var(--sync-button-border-color);
        color: var(--sync-primary-color);
        font-size: 1.6rem;
      }
    }
  }
}
