.printing-edit-photo__preview-image {
  position: relative;
  height: 100%;
  margin-bottom: 2rem;
  border-radius: 6.3rem;
  background-color: transparent;
}

.printing-edit-photo__preview-edit-wrapper {
  display: flex;
  gap: 2rem;
  height: 65rem;
}

.printing-edit-photo__preview-image-wrapper {
  height: inherit;
  aspect-ratio: 3 / 2;
  position: relative;
  border-radius: 4rem;
  padding: 1rem;

  img {
    width: 100%;
    height: 100%;
  }

  .printing-edit-photo__cropper-wrapper {
    border-radius: 4rem;
    border: 0.5rem solid white;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  .printing-edit-photo__cropper {
    width: 100%;
    height: 100%;
  }
}

.printing-edit-photo__edit-panel {
  width: 40rem;
  height: 100%;
  border-radius: 5rem;
  background-color: white;
  padding: 2rem;

  .printing-edit-photo__edit-panel-title {
    text-align: center;
    color: var(--sync-primary-color);
    font-size: 3rem;
    font-weight: 900;
    margin-bottom: 4rem;
  }

  .printing-edit-photo__edit-panel--row {
    margin: 2rem 0;

    .printing-edit-photo__edit-panel--col {
      display: flex;
      gap: 2rem;

      & > * {
        flex: 1;
      }
    }
  }
}

.printing-edit-photo__btn-re-setup {
  width: 100%;
  text-align: center;
  padding: 1.2rem 2rem;
  height: unset;
  font-size: 1.6rem;
  border: none;

  svg {
    margin-right: 1rem;
    width: 3rem;
    height: 3rem;
  }

  & > * {
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
  }

  .pb-typography {
    font-size: 1.6rem;
    font-weight: 900;
  }
}

.printing-edit-photo__edit-actions {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.printing-edit-photo__edit-action-select-amount {
  display: flex;
}

.printing-edit-photo__edit-action-crop {
  display: flex;
  align-items: center;
  margin-left: 20rem;

  .printing-edit-photo__btn-show-crop {
    display: flex;
    align-items: center;

    .printing-edit-photo__icon-crop {
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      background-color: var(--sync-primary-color);
      border: 2px solid var(--sync-button-border-color);
      text-align: center;
      display: flex;
      align-items: center;
    }

    svg {
      margin: auto;
      width: 3rem;
      height: 3rem;

      path {
        stroke: var(--sync-button-text-color);
      }
    }
  }

  .pb-typography {
    font-size: 3rem;
    font-weight: 900;
    text-align: center;
    margin-left: 1rem;
  }
}
