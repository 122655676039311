.shooting-page img {
  pointer-events: none;
}

.webcam-frame {
  position: relative;
  width: 160rem;
  height: 80.7rem;
  margin-top: 12.2rem;
  margin-bottom: 2.096rem;
  border-radius: 6.3rem;
  /* background-color: #010001; */
  background-color: transparent;
}

.webcam-frame.is-enough-photo {
  background-color: transparent;
}

.webcam-frame img {
  max-width: 100%;
  max-height: 100%;
  height: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}

.webcam-frame-wrapper {
  height: inherit;
  aspect-ratio: 1 / 1;
  position: relative;
  border-radius: 4rem;
  padding: 1rem;
  background-image: var(--sync-left-frame-dashed-border);
}

.webcam-frame-wrapper > img {
  border-radius: 4rem;
  border: 0.5rem solid white;
  width: 100%;
}

.btn-retake-a-photo {
  position: absolute;
  width: 9.6rem;
  height: 9.6rem;
  bottom: 2rem;
  right: 2rem;
  background-color: white;
  border-radius: 1.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: var(--z-index-button-on-backdrop);
  padding: 2rem 1rem 1.5rem 1rem;
}

.btn-retake-a-photo svg path {
  stroke: var(--sync-primary-color);
}

.btn-retake-a-photo .text-retake-a-photo {
  margin-top: 0.5rem;
  font-style: normal;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 2.2rem;
  color: var(--sync-secondary-color);
}

.retake-photo-img-result {
  top: 0px;
  left: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: var(--z-index-retake-result);
}

.screenshot-img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.freeze-frame.pb-loader {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  border-radius: inherit;
  z-index: var(--z-index-freeze-webcam-loading);
  top: 0rem;
  left: 0rem;
}

.freeze-frame .pb-loader-icon-wrapper {
  background-image: unset;
}

.recently-photo {
  background: white;
  border-radius: 1.5rem;
  padding: 0.789rem 0 0.789rem 0.86rem;
  height: 9.9rem;
}

.photo-slot {
  width: 8.322rem;
  height: 8.322rem;
  background-color: var(--sync-title-color);
  border-radius: 1.4rem;
  padding: 0.43rem 0.43rem 0.502rem 0.502rem;
  flex-shrink: 0;
  position: relative;
}

.photo-slot:not(:first-child) {
  margin-left: 1rem;
}

.photo-slot.has-photo {
  padding: 0px;
}

.photo-slot.selected-photo {
  border: 0.4rem solid var(--sync-secondary-color);
  padding: 0.2rem;
}

.photo-slot.has-photo img {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  -o-object-fit: cover;
  object-fit: cover;
}

.count-recently-photo {
  width: 8.322rem;
}

.btn-take-a-photo {
  position: absolute;
  top: 2.5rem;
}

.shooting-page .action-wrapper {
  top: 2.5rem;
  position: absolute;
  display: flex;
}

.shooting-page .action-wrapper > *:not(:last-of-type) {
  margin-right: 5.2rem;
}

.photo-slot-retake-layer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  border-radius: inherit;
}

.photo-slot-retake-layer svg path {
  stroke: var(--sync-primary-color);
}

.photo-slot.retaking-photo-thumb .photo-slot-retake-layer svg path {
  stroke: var(--sync-secondary-color);
}

/**************************/
/* shooting-page-overhead */
.shooting-page-overhead {
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.shooting-page-overhead .action-wrapper {
  width: 100%;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  top: 0;
}

.shooting-page-overhead .action-wrapper > * {
  margin: 2rem 0 !important;
}

.shooting-page-overhead .action-wrapper,
.shooting-page-overhead .recently-photo-wrapper {
  width: 100%;
  position: relative;
}

.shooting-page-overhead .webcam-frame {
  width: unset;
  height: calc(100vh - 4rem);
  margin: 2rem;
}

.shooting-page-overhead .webcam-frame-3per2 {
  height: calc(100vh - 12rem);
}

.shooting-page-overhead .recently-photo {
  width: 9.9rem;
  height: unset;
  min-height: 9.9rem;
  flex-direction: column;
}

.shooting-page-overhead .photo-slot {
  margin-left: 0 !important;
  margin-bottom: 1rem;
}

.shooting-page-overhead .count-recently-photo {
  height: 6rem;
}

/**************************/
/* Vertical Screen */
.app-vertical-screen .action-wrapper {
  position: relative;
  margin: 10rem 0;
}

.app-vertical-screen .webcam-frame {
  height: 100%;
  margin-top: 5rem;
  margin-bottom: 5rem;
  min-height: 0;
}

.app-vertical-screen .webcam-frame.webcam-frame-3per2 {
  height: 68.7rem;
  margin-top: 5rem;
}

.app-vertical-screen .webcam-frame.webcam-frame-5per4 {
  height: 80rem;
}

.app-vertical-screen .webcam-frame.webcam-frame-4per3 {
  height: 78rem;
}

.app-vertical-screen .btn-retake-a-photo {
  bottom: 5rem;
  right: 5rem;
}
