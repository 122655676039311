.pb-input {
  border: 0.5rem solid var(--sync-button-border-color);
  border-radius: 6rem;
  text-align: right;
  padding: 2rem 3.1rem 2rem;
  color: var(--sync-primary-color);
  font-weight: 700;
}

.pb-input.pb-input-disabled {
}

.pb-input.pb-input-error {
}

.pb-input-number {
  display: flex;
  align-items: center;

  & > * {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  & > .pb-typography {
    font-weight: 700;
  }

  button {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    border: 2px solid var(--sync-button-border-color);
    background-color: var(--sync-primary-color);
    color: var(--sync-button-text-color);
    font-size: 4rem;

    .pb-typography {
      line-height: 0;
      font-size: 2.5rem;
      font-weight: 900;
    }
  }

  .printing-edit-photo__amount-label {
    background-color: var(--sync-secondary-color);
    height: 4rem;
    border-radius: 1rem;
    padding: 0 2.5rem;
    border: 2px solid var(--sync-button-border-color);

    .pb-typography {
      /* line-height: 0; */
      font-size: 3rem;
      font-weight: 900;
      text-align: center;
    }
  }
}
