.print-start-page__item {
  width: 60rem;
  height: 67rem;
  background-image: var(--sync-left-frame-dashed-border);
}

.print-start-page__btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 3rem;
  background: url('assets/v2/images/blue_cartoon_background.png');
  background-size: 60rem;
  background-color: var(--sync-left-frame-background-color);
  padding: 2rem;
}

.print-start-page__btn--title {
  margin-top: 4rem;
}

.print-start-page__item--right {
  margin-left: 8rem;
  background-image: var(--sync-right-frame-dashed-border);
}

.print-start-page__item--right .print-start-page__btn {
  background: url('assets/v2/images/blue_cartoon_background.png');
  background-size: 60rem;
  background-color: var(--sync-right-frame-background-color);
}

.print-start-page__btn img {
  max-height: 100%;
  max-width: 100%;
  min-height: 0;
}

.print-start-page__mascot {
  height: 70%;
  margin-top: 2rem;
  margin: auto;
}

.print-start-page__supported-bank-text {
  margin-top: 2rem;
}

.print-start-page__supported-bank {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}

.print-start-page__supported-bank img {
  height: 6rem;
  width: 6rem;
}

.print-start-page__btn-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* App Vertical Screen */
.app-vertical-screen .print-start-page {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.app-vertical-screen .print-start-page__item {
  width: 45rem;
  height: 57.45rem;
}

.app-vertical-screen .print-start-page__btn-group {
  height: 100%;
}
